import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import './styles.scss';

class NavbarComponent extends PureComponent {
    toggleMenu() {
        let navbar = document.getElementById("navbar");
        if (navbar.classList.contains("open")) {
            navbar.classList.remove("open");
        } else {
            navbar.classList.add("open");
        }
    }

    render() {
        var homeClasses = "";
        var galleryClasses = "";
        var eventsClasses = "";
        var sailingClasses = "";
        var speedboatsClasses = "";
        var scaleModelsClasses = "";
        var contactClasses = "";

        switch (this.props.selected) {
            case "home":
                homeClasses += " selected";
                break;
            case "gallery":
                galleryClasses += " selected";
                break;
            case "events":
                eventsClasses += " selected";
                break;
            case "sailing":
                sailingClasses += " selected";
                break;
            case "speedboats":
                speedboatsClasses += " selected";
                break;
            case "scale-models":
                scaleModelsClasses += " selected";
                break;
            case "contact":
                contactClasses += " selected";
                break;
        
            default:
                break;
        }

        return(
            <div id="navbar">
                <div id="menu-icon-container" onClick={ this.toggleMenu.bind(this) }>
                    <MenuIcon className='menu-icon' />
                </div>
                <Link to="/" id="homeTab" className={ "tab" + homeClasses }>Home</Link>
                <Link to="/gallery" id="galleryTab" className={ "tab" + galleryClasses }>Gallery</Link>
                <Link to="/events" id="eventsTab" className={ "tab" + eventsClasses }>Events</Link>
                <Link to="/sailing" id="sailingTab" className={ "tab" + sailingClasses }>Sailing</Link>
                <Link to="/speedboats" id="speedboatsTab" className={ "tab" + speedboatsClasses }>Speedboats</Link>
                <Link to="/scale-models" id="scaleModelsTab" className={ "tab" + scaleModelsClasses }>Scale Models</Link>
                <Link to="/contact" id="contactTab" className={ "tab" + contactClasses }>Contact</Link>
            </div>
        );
    }
}

export default NavbarComponent;