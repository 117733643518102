import React, { Component } from 'react';
import LeaderboardComponent from './LeaderboardComponent';

class LeaderboardContainer extends Component {
    render() {
        return(
            <LeaderboardComponent 
                title={this.props.title}
                data={this.props.data}
                hidden={this.props.hidden}
            />
        );
    }
}

export default LeaderboardContainer;