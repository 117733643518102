import React, { PureComponent } from 'react';
import { ExpandMore, ExpandLess, EmojiEvents } from '@mui/icons-material';
import './styles.scss';

class LeaderboardComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            hidden: this.props.hidden
        };
    }

    toggle() {
        this.setState({
            hidden: !this.state.hidden
        });
    }

    render() {
        const maxPoints = this.props.data[0].points;
        const rows = this.props.data.map((item, index) => 
            <tr key={index} style={{backgroundSize: ((item.points / maxPoints) * 100) + "%"}}>
                <td>{index + 1}{index < 3 ? <EmojiEvents className={'trophy pos' + (index + 1)} /> : null}</td>
                <td>{item.name}</td>
                <td className='points'>{ item.points }</td>
            </tr>
        );

        let arrow = <ExpandLess className='arrow' />;
        if (this.state.hidden) {
            arrow = <ExpandMore className='arrow' />
        }

        return(
            <div className="leaderboard">
                { arrow }
                <h2 id='title' onClick={this.toggle.bind(this)}>{this.props.title}</h2>
                <table className={this.state.hidden ? 'hide' : ''}>
                    <thead>
                        <tr>
                            <th className='position-title'>Position</th>
                            <th className='name-title'>Name</th>
                            <th className='points-title'>Points</th>
                        </tr>
                    </thead>
                    <tbody>
                        { rows }
                    </tbody>
                </table>
            </div>
        );
    }
}

export default LeaderboardComponent;