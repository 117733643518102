import React, { PureComponent } from 'react';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Dropdown from '../../components/Dropdown';
import Calendar from '../../components/Calendar';
import WCMBC_Logo from '../../assets/wcmbc_logo.svg';
import './styles.scss';

const monthNames = [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER"
];

class Events extends PureComponent {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    getMonthAndYear() {
        let date = new Date();
        let monthName = monthNames[date.getMonth()];
        let year = date.getFullYear();
        return monthName + " " + year;
    }

    render() {

        return (
            <div id="events-page">
                <Navbar selected="events" />
                <div id="events-page-content">
                    <Header />
                    <div className="text-container">
                        <img src={WCMBC_Logo} alt="wcmbc logo" className='logo' />
                        <h1>Events</h1>
                        <p>
                            Events for the month can be seen on the Calendar. <br />
                            Future events are mentioned in the monthly newsletters sent out by email to all club members.
                        </p>
                        <hr />
                        <Dropdown
                            title="Newsletters"
                            hidden
                            content={
                                <div>
                                    <p>*Click to download</p>
                                    <a href={process.env.PUBLIC_URL + '/data/newsletters/WCMBC Jan 2024.pdf'} download>WCMBC January 2024</a><br />
                                    <a href={process.env.PUBLIC_URL + '/data/newsletters/WCMBC FEB 2024.pdf'} download>WCMBC February 2024</a><br />
                                    <a href={process.env.PUBLIC_URL + '/data/newsletters/WCMBC March 2024.pdf'} download>WCMBC March 2024</a><br />
                                    <a href={process.env.PUBLIC_URL + '/data/newsletters/WCMBC April 2024.pdf'} download>WCMBC April 2024</a><br />
                                    <a href={process.env.PUBLIC_URL + '/data/newsletters/WCMBC May 2024.pdf'} download>WCMBC May 2024</a><br />
                                    <a href={process.env.PUBLIC_URL + '/data/newsletters/WCMBC June 2024.pdf'} download>WCMBC June 2024</a><br />
                                    <a href={process.env.PUBLIC_URL + '/data/newsletters/WCMBC July 2024.pdf'} download>WCMBC July 2024</a><br />
                                </div>
                            }
                        />
                        <hr />
                        <h1>Calendar</h1>
                        <h1 className='month'>{this.getMonthAndYear()}</h1>
                        <div className='race-days'>
                            <div className='racing block' />
                            <h3>-&gt; Race Days</h3>
                            <ul>
                                <li>Every 1st and 3rd Sunday of the month</li>
                                <li>11:30 - Yachts - Regatta 1</li>
                                <li>12:00 - Speedboats - Race 1</li>
                                <li>12:30 - Yachts - Regatta 2</li>
                                <li>13:00 - Speedboats - Race 2</li>
                            </ul>
                        </div>
                        <Calendar />
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Events;