import React, { Component } from 'react';
import CardComponent from './CardComponent';

class CardContainer extends Component {
    render() {
        return(
            <CardComponent 
                name={ this.props.name }
                avatar={ this.props.avatar }
                titles={ this.props.titles }
                email={ this.props.email }
            />
        );
    }
}

export default CardContainer;