import React, { Component } from 'react';
import CarouselComponent from './CarouselComponent';

class CarouselContainer extends Component {
    render() {
        return(
            <CarouselComponent 
                {...this.props}
            />
        );
    }
}

export default CarouselContainer;