import React, { PureComponent } from 'react';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header';
import Carousel from '../../components/Carousel';
import Footer from '../../components/Footer';
import WCMBC_Logo from '../../assets/wcmbc_logo.svg';
import './styles.scss';

class ScaleModels extends PureComponent {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <div id="scale-models-page">
                <Navbar selected="scale-models"/>
                <div id="scale-models-page-content">
                    <Header />
                    <div className="text-container">
                        <img src={WCMBC_Logo} alt="wcmbc logo" className='logo' />
                        <h2>Scale Models</h2>
                        <p>
                            Every 2nd Sunday of the month is scale day at the Sonstraal Dam.
                        </p>
                        <p>
                            The objective here is to create miniature replicas of actual full-size 
                            craft of various descriptions, typically tugs, fishing boats, sail-driven 
                            craft, submarines, naval ships, etc.
                        </p>
                        <p>
                            Activities include:
                        </p>
                        <ul>
                            <li>Tug towing</li>
                            <li>Appearance and workmanship</li>
                            <li>Formation sailing</li>
                            <li>Casual sailing</li>
                            <li>And more...</li>
                        </ul>
                    </div>
                    <Carousel 
                        showStatus={ false }
                        showThumbs={ false }
                        slides={[
                            { 
                                image: require('../../assets/Carousels/Scale Models/models_0.jpg'), 
                                text: "SA Navy vessels - moored (J Bulterman)"
                            },
                            { 
                                image: require('../../assets/Carousels/Scale Models/models_1.jpg'), 
                                text: "Kriegsmarine MK 9B U-Boat - Diving (P Botha)"
                            },
                            { 
                                image: require('../../assets/Carousels/Scale Models/models_2.jpg'), 
                                text: "Ton-Class Minesweeper - Men at work (J Bulterman)"
                            },
                            { 
                                image: require('../../assets/Carousels/Scale Models/models_3.jpg'), 
                                text: "The Bismarck (P Botha)"
                            },
                            { 
                                image: require('../../assets/Carousels/Scale Models/models_4.jpg'), 
                                text: "John Ross/Smit Amandla - Water canons (S Bok)"
                            },
                            { 
                                image: require('../../assets/Carousels/Scale Models/models_5.jpg'), 
                                text: "Parat Tug (T Norton)"
                            },
                        ]}
                    />
                </div>
                <Footer />
            </div>
        );
    }
}

export default ScaleModels;