import React, { Component } from 'react';
import DropdownComponent from './DropdownComponent';

class DropdownContainer extends Component {
    render() {
        return(
            <DropdownComponent 
                title={this.props.title}
                content={this.props.content}
                hidden={this.props.hidden}
            />
        );
    }
}

export default DropdownContainer;