import React, { PureComponent} from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';

import Home from './routes/Home/Home';
import Gallery from './routes/Gallery/Gallery';
import Events from './routes/Events/Events';
import Sailing from './routes/Sailing/Sailing';
import Contact from './routes/Contact/Contact';
import Speedboats from './routes/Speedboats/Speedboats';
import ScaleModels from './routes/ScaleModels/ScaleModels';
import Calculator from './routes/Calculator/Calculator';

class Router extends PureComponent {
    render() {
        return(
            <HashRouter>
                <Switch>
                    <Route exact path="/" component={ Home } />
                    <Route exact path="/gallery" component={ Gallery } />
                    <Route exact path="/events" component={ Events } />
                    <Route exact path="/sailing" component={ Sailing } />
                    <Route exact path="/speedboats" component={ Speedboats } />
                    <Route exact path="/scale-models" component={ ScaleModels } />
                    <Route exact path="/contact" component={ Contact } />
                    <Route exact path="/calc" component={ Calculator } />
                </Switch>
            </HashRouter>
        );
    }
}

export default Router;