import React, { PureComponent } from 'react';
import './styles.scss';

class FooterComponent extends PureComponent {
    render() {
        return(
            <div id="footer">
                Copyright © 2024 | Western Cape Model Boat Club | All rights reserved
            </div>
        );
    }
}

export default FooterComponent;