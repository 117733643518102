import React, { PureComponent } from 'react';
import './styles.scss';

class HeaderComponent extends PureComponent {
    render() {
        return(
            <div className="header">
                <div className="header-images">
                    <div className="sailing-logo" />
                    <div className="spinning-wheel" />
                    <div className="speedboat-logo" />
                </div>
                <div className="wcmbc-text-logo" />
            </div>
        );
    }
}

export default HeaderComponent;