import React, { Component } from 'react';
import FooterComponent from './FooterComponent';

class FooterContainer extends Component {
    render() {
        return(
            <FooterComponent />
        );
    }
}

export default FooterContainer;