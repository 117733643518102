import React, { Component } from 'react';
import HeaderComponent from './HeaderComponent';

class HeaderContainer extends Component {
    render() {
        return(
            <HeaderComponent />
        );
    }
}

export default HeaderContainer;