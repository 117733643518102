import React, { PureComponent } from 'react';
import DefaultAvatar from '@mui/icons-material/AccountBox';
import EmailIcon from '@mui/icons-material/Email';
import './styles.scss';

class CardComponent extends PureComponent {
    render() {
        // Name
        const name = <div className="name">{ this.props.name }</div>;
        // Avatar
        let avatar = <DefaultAvatar className='avatar'/>;
        if (this.props.avatar) {
            avatar = <img className="avatar" src={ this.props.avatar } alt="avatar" />;
        }
        // Titles
        const titles = this.props.titles.map((title, index) => 
            <li key={ "title_" + index } className='title'>{ title }</li>    
        );
        // Email
        let email = null;
        if (this.props.email) {
            email = 
            <div className="email">
                <EmailIcon className='icon' />
                <a href={"mailto:" + this.props.email}>{ this.props.email }</a>
            </div>;
        }
        
        return(
            <div className="card">
                { avatar }
                { name }
                <ul className='titles'>
                    { titles }
                </ul>
                { email }
            </div>
        );
    }
}

export default CardComponent;