import React, { Component } from 'react';
import CalendarComponent from './CalendarComponent';

class CalendarContainer extends Component {
    render() {
        return(
            <CalendarComponent 
                events={this.props.events}
            />
        );
    }
}

export default CalendarContainer;