import React, { PureComponent } from 'react';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header';
import Carousel from '../../components/Carousel';
import Footer from '../../components/Footer';
import Leaderboard from '../../components/Leaderboard';
import Leaderboard2023Data from '../../data/speedboats_leaderboard_2023.json';
import PointsSystemData from '../../data/pointsSystem_2023.json';
import Motors from '../../assets/Motor_Sizes.jpg';
import Batteries from '../../assets/Batteries.jpg';
import { Link } from 'react-router-dom';
import Dropdown from '../../components/Dropdown';
import WCMBC_Logo from '../../assets/wcmbc_logo.svg';
import './styles.scss';

const lastUpdated = "18-08-2024";

class Speedboats extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            Leaderboard2024Data: null
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        fetch("data/speedboats_leaderboard_2024.json", {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then((response) => response.json())
        .then((json) => this.setState({ Leaderboard2024Data: json }));
    }

    render() {
        return(
            <div id="speedboats-page">
                <Navbar selected="speedboats"/>
                <div id="speedboats-page-content">
                    <Header />
                    <div className="text-container">
                        <img src={WCMBC_Logo} alt="wcmbc logo" className='logo' />
                        <h2>Speedboats</h2>
                        <p>
                            The speedboats division has been less active over the years - however, 
                            the Club would like to see new interest in this aspect of the hobby.
                        </p>
                        <p>
                            A restriction, however, is that <strong>only electric-driven</strong> models are allowed, 
                            and noise must be within acceptable limits. These considerations are to 
                            ensure that surrounding residential homes are not subjected to any nuisance! 
                            However, this is in respect of the Sonstraal Dam venue; there are 
                            possibilities of other venues.
                        </p>
                    </div>
                    <div className="text-container">
                        <h2>Classes</h2>
                        <hr/>
                        <div className='class-title'>Open Class:</div>
                        <div className='icons'>
                            <img src={Motors} alt='motors' className='class-icon'/>
                            <img src={Batteries} alt='batteries' className='class-icon'/>
                        </div>
                        <ol className='class-list'>
                            <li>Any hull</li>
                            <li>Any (ELECTRIC!) Motor</li>
                            <li>Any Battery</li>
                        </ol>
                        <hr/>
                        <div className='class-title'>Vector Class:</div>
                        <ol className='class-list'>
                            <li>Vector SR80 hull</li>
                            <li>Brushless Motor</li>
                            <li>2-4S LiPo</li>
                        </ol>
                    </div>
                    <div className='text-container'>
                        <h2>Build Help</h2>
                        <p>
                            Below is a link to a YouTube video of an in-depth explanation 
                            on how to pick all the parts to build a RC speed boat:
                        </p>
                        <a href='https://youtu.be/2tuvoAfPMYU' target="_blank" rel="noreferrer">YouTube - Selecting a motor, battery and prop</a>
                        <p>
                            Here's a calculator based off the info in the video:
                        </p>
                        <Link to="/calc">Calculator - Selecting a motor, battery and prop</Link>
                    </div>
                    <Carousel 
                        showStatus={ false }
                        showThumbs={ false }
                        slides={[
                            { 
                                image: require('../../assets/Carousels/Speedboats/speedboats_0.jpg'), 
                                text: "Vector SR80 getting some speed" 
                            },
                            { 
                                image: require('../../assets/Carousels/Speedboats/speedboats_1.jpg'), 
                                text: "Vector SR80 close up" 
                            },
                            { 
                                image: require('../../assets/Carousels/Speedboats/speedboats_2.jpg'), 
                                text: "Flatty - Top and bottom hull" 
                            },
                            { 
                                image: require('../../assets/Carousels/Speedboats/speedboats_3.jpg'), 
                                text: "Flatty - Component placement" 
                            },
                            { 
                                image: require('../../assets/Carousels/Speedboats/speedboats_4.jpg'), 
                                text: "Ready for race day!" 
                            },
                            { 
                                image: require('../../assets/Carousels/Speedboats/speedboats_5.jpg'), 
                                text: "Antons mini jet boat" 
                            },
                        ]}
                    />
                    {this.state.Leaderboard2024Data ?
                    <Leaderboard
                        title={ "Leaderboard (2024) (Last Updated: " + lastUpdated + ")" }
                        data={ this.state.Leaderboard2024Data }
                    />
                    :
                    <div>Loading...</div>
                    }
                    <Dropdown 
                        title="Points System (2024)"
                        content={(
                            <div>
                                <p>
                                    This year we're trying out a new points system.
                                    We'll be using <a href='https://www.halsraceresults.com/'>Hal's Race Results</a> software 
                                    to generate race results.
                                </p>
                                <p>
                                    The way it works is as follows:
                                </p>
                                <ul>
                                    <li>A stopwatch is started at the start of the race.</li>
                                    <li>Each boats finishing time is recorded.</li>
                                    <li>The times are entered into Hal's software.</li>
                                    <li>Hal's calculates results including handicaps.</li>
                                    <li>The final results are exported from Hal's software into an excel spreadsheet.</li>
                                    <li>The excel sheet for each race is available for download from the Race Results section above.</li>
                                    <li>The total points for the year are tallied up and displayed in the Leaderboard above.</li>
                                </ul>
                            </div>
                        )}
                    />
                    <hr />
                    <Leaderboard
                        title="Leaderboard (2023)"
                        data={ Leaderboard2023Data }
                        hidden={true}
                    />
                    <Leaderboard
                        title="Points System (2023)"
                        data={ PointsSystemData }
                        hidden={true}
                    />
                </div>
                <Footer />
            </div>
        );
    }
}

export default Speedboats;