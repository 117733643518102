import React, { PureComponent } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './styles.scss';

class CarouselComponent extends PureComponent {
    toggleFullscreen(index, item) {
        if (document.fullscreen) {
            document.exitFullscreen();
        } else {
            let carouselEl = document.getElementsByClassName("carousel-slider")[0];
            carouselEl?.requestFullscreen();
        }
    }

    render() {
        const slides = this.props.slides.map((slide, index) =>
            <div key={ "slide_" + index }>
                <img src={slide.image } alt={ "slide_" + index } />
                {slide.text && <p className='legend'>{ slide.text }</p>}
            </div>
        );
        
        return(
            <Carousel onClickItem={ this.toggleFullscreen.bind(this) }
                autoPlay={ true }
                infiniteLoop={ true }
                showArrows={ true }
                // showStatus={ false }
                // showThumbs={ false }
                useKeyboardArrows={ true }
                stopOnHover={ false }
                interval={ 5000 }
                {...this.props}
            >
                { slides }
            </Carousel>
        );
    }
}

export default CarouselComponent;