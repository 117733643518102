import React, { PureComponent } from 'react';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header';
import Carousel from '../../components/Carousel';
import Footer from '../../components/Footer';
import WCMBC_Logo from '../../assets/wcmbc_logo.svg';
import './styles.scss';

class Gallery extends PureComponent {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <div id="gallery-page">
                <Navbar selected="gallery"/>
                <div id="gallery-page-content">
                    <Header />
                    <div className="text-container">
                        <img src={WCMBC_Logo} alt="wcmbc logo" className='logo' />
                        <h2>Gallery</h2>
                        <p>The club as well as a few of our members have YouTube Channels:</p>
                        <ul>
                            <li><a href='https://www.youtube.com/@WCMBC-Sonstraal' target='_blank' rel='noreferrer'>WCMBC</a></li>
                            <li><a href='http://www.youtube.com/@ColinRudert' target='_blank' rel='noreferrer'>Colin Rudert</a></li>
                            <li><a href='http://www.youtube.com/@KimDeJong-mv2fd' target='_blank' rel='noreferrer'>Kim de Jong</a></li>
                        </ul>
                        <p>We also have a Facebook page:</p>
                        <ul>
                            <li><a href='https://www.facebook.com/groups/708478039297543/'>Western Cape Model Boat Club</a></li>
                        </ul>
                        <p>Here are some pics from our recent events.</p>
                        <p>Click on any of the slideshows to view them in fullscreen (then rotate to landscape if you are on your phone).</p>
                    </div>
                    {/* <div className="text-container">
                        <h2>21-04-2024</h2>
                        <Carousel 
                            slides={[
                                { 
                                    image: require('../../assets/Carousels/Gallery/21-04-2024/IMG_20240421_110250.jpg')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/21-04-2024/IMG_20240421_110302.jpg')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/21-04-2024/IMG_20240421_110427.jpg')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/21-04-2024/IMG_20240421_121557.jpg')
                                },
                            ]}
                        />
                    </div> */}
                    <div className="text-container">
                        <h2>14-04-2024 (Tug Day!)</h2>
                        <Carousel 
                            slides={[
                                { 
                                    image: require('../../assets/Carousels/Gallery/14-04-2024-TugDay/DSCN6762.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/14-04-2024-TugDay/DSCN6767.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/14-04-2024-TugDay/DSCN6768.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/14-04-2024-TugDay/DSCN6770.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/14-04-2024-TugDay/DSCN6771.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/14-04-2024-TugDay/DSCN6772.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/14-04-2024-TugDay/DSCN6773.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/14-04-2024-TugDay/DSCN6774.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/14-04-2024-TugDay/DSCN6775.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/14-04-2024-TugDay/DSCN6776.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/14-04-2024-TugDay/DSCN6777.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/14-04-2024-TugDay/DSCN6780.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/14-04-2024-TugDay/DSCN6781.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/14-04-2024-TugDay/DSCN6784.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/14-04-2024-TugDay/DSCN6786.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/14-04-2024-TugDay/DSCN6788.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/14-04-2024-TugDay/DSCN6790.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/14-04-2024-TugDay/DSCN6791.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/14-04-2024-TugDay/DSCN6792.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/14-04-2024-TugDay/DSCN6798.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/14-04-2024-TugDay/DSCN6802.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/14-04-2024-TugDay/DSCN6805.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/14-04-2024-TugDay/DSCN6806.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/14-04-2024-TugDay/DSCN6810.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/14-04-2024-TugDay/DSCN6811.JPG')
                                },
                            ]}
                        />
                    </div>
                    {/* <div className="text-container">
                        <h2>07-04-2024</h2>
                        <Carousel 
                            slides={[
                                { 
                                    image: require('../../assets/Carousels/Gallery/07-04-2024/DSCN6733.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/07-04-2024/DSCN6741.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/07-04-2024/DSCN6743.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/07-04-2024/DSCN6745.JPG')
                                },
                            ]}
                        />
                    </div>
                    <div className="text-container">
                        <h2>17-03-2024</h2>
                        <Carousel 
                            slides={[
                                { 
                                    image: require('../../assets/Carousels/Gallery/17-03-2024/DSCN6692.JPG'),
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/17-03-2024/DSCN6701.JPG'),
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/17-03-2024/DSCN6702.JPG'),
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/17-03-2024/DSCN6704.JPG'),
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/17-03-2024/DSCN6705.JPG'),
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/17-03-2024/DSCN6706.JPG'),
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/17-03-2024/DSCN6720.JPG'),
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/17-03-2024/DSCN6727.JPG'),
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/17-03-2024/DSCN6728.JPG'),
                                },
                            ]}
                        />
                    </div>
                    <div className="text-container">
                        <h2>03-03-2024</h2>
                        <Carousel 
                            slides={[
                                { 
                                    image: require('../../assets/Carousels/Gallery/03-03-2024/DSCN6678.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/03-03-2024/DSCN6680.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/03-03-2024/DSCN6681.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/03-03-2024/DSCN6685.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/03-03-2024/DSCN6686.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/03-03-2024/DSCN6687.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/03-03-2024/DSCN6691.JPG')
                                },
                            ]}
                        />
                    </div> */}
                    <div className="text-container">
                        <h2>25-02-2024 (Merchant Day!)</h2>
                        <Carousel 
                            slides={[
                                { 
                                    image: require('../../assets/Carousels/Gallery/25-02-2024-MerchantDay/DSCN6653.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/25-02-2024-MerchantDay/DSCN6654.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/25-02-2024-MerchantDay/DSCN6655.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/25-02-2024-MerchantDay/DSCN6658.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/25-02-2024-MerchantDay/DSCN6659.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/25-02-2024-MerchantDay/DSCN6661.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/25-02-2024-MerchantDay/DSCN6664.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/25-02-2024-MerchantDay/DSCN6665.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/25-02-2024-MerchantDay/DSCN6666.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/25-02-2024-MerchantDay/DSCN6668.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/25-02-2024-MerchantDay/DSCN6670.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/25-02-2024-MerchantDay/DSCN6672.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/25-02-2024-MerchantDay/DSCN6675.JPG')
                                },
                            ]}
                        />
                    </div>
                    <div className="text-container">
                        <h2>11-02-2024 (Record Day!)</h2>
                        <Carousel 
                            slides={[
                                { 
                                    image: require('../../assets/Carousels/Gallery/11-02-2024-RecordDay/DSCN6626.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/11-02-2024-RecordDay/DSCN6627.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/11-02-2024-RecordDay/DSCN6630.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/11-02-2024-RecordDay/DSCN6631.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/11-02-2024-RecordDay/DSCN6632.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/11-02-2024-RecordDay/DSCN6633.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/11-02-2024-RecordDay/DSCN6636.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/11-02-2024-RecordDay/DSCN6637.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/11-02-2024-RecordDay/DSCN6638.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/11-02-2024-RecordDay/DSCN6639.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/11-02-2024-RecordDay/DSCN6641.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/11-02-2024-RecordDay/DSCN6644.JPG')
                                },
                            ]}
                        />
                    </div>
                    {/* <div className="text-container">
                        <h2>04-02-2024</h2>
                        <Carousel 
                            slides={[
                                { 
                                    image: require('../../assets/Carousels/Gallery/04-02-2024/DSCN6600.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/04-02-2024/DSCN6601.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/04-02-2024/DSCN6604.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/04-02-2024/DSCN6606.JPG')
                                },
                            ]}
                        />
                    </div>
                    <div className="text-container">
                        <h2>21-01-2024</h2>
                        <Carousel 
                            slides={[
                                { 
                                    image: require('../../assets/Carousels/Gallery/21-01-2024/DSCN6553.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/21-01-2024/DSCN6559.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/21-01-2024/DSCN6563.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/21-01-2024/DSCN6566.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/21-01-2024/DSCN6568.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/21-01-2024/DSCN6571.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/21-01-2024/DSCN6582.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/21-01-2024/DSCN6584.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/21-01-2024/DSCN6587.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/21-01-2024/DSCN6588.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/21-01-2024/DSCN6595.JPG')
                                },
                            ]}
                        />
                    </div>
                    <div className="text-container">
                        <h2>14-01-2024</h2>
                        <Carousel 
                            slides={[
                                { 
                                    image: require('../../assets/Carousels/Gallery/14-01-2024/IMG20240114105732a.jpg')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/14-01-2024/IMG20240114111636.jpg')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/14-01-2024/IMG20240114121157.jpg')
                                },
                            ]}
                        />
                    </div>
                    <div className="text-container">
                        <h2>07-01-2024</h2>
                        <Carousel 
                            slides={[
                                { 
                                    image: require('../../assets/Carousels/Gallery/07-01-2024/DSCN6439.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/07-01-2024/DSCN6459.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/07-01-2024/DSCN6464.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/07-01-2024/DSCN6479.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/07-01-2024/DSCN6483.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/07-01-2024/DSCN6502.JPG')
                                },
                                { 
                                    image: require('../../assets/Carousels/Gallery/07-01-2024/DSCN6518.JPG')
                                },
                            ]}
                        />
                    </div> */}
                </div>
                <Footer />
            </div>
        );
    }
}

export default Gallery;