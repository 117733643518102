import React, { PureComponent } from 'react';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import HullLengthLogo from '../../assets/logos/hullLengthLogo.jpg';
import PowerLogo from '../../assets/logos/powerLogo.jpg';
import BattLogo from '../../assets/logos/battLogo.jpg';
import MotorLogo from '../../assets/logos/motorLogo.jpg';
import PropLogo from '../../assets/logos/propLogo.jpg';
import './styles.scss';

class Calculator extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            hullLength: 50,
            units: 'cm',
            inches: 19.685,
            power: 348.986,
            battMilliAmps: 5000,
            rpm: 30000
        }
    }
    
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    onHullLengthChange(event) {
        let hullLength = event.target.value ? +event.target.value : 0;
        this.update(hullLength, this.state.units);
    }

    handleUnitsChange(event) {
        let units = event.target.value ? event.target.value : 'cm';
        this.update(this.state.hullLength, units);
    }

    handleBattMilliAmpChange(event) {
        let battMilliAmps = event.target.value ? +event.target.value : 0;
        this.setState({
            battMilliAmps: battMilliAmps
        });
    }

    handleRpmChange(event) {
        let rpm = event.target.value ? +event.target.value : 0;
        this.setState({
            rpm: rpm
        });
    }

    update(hullLength, units) {
        let inches = hullLength;
        if (units === 'cm') {
            inches *= 0.3937;
        }
        let power = (4.279 * (inches * inches)) - (163.1 * inches) + 1901.5;
        this.setState({
            hullLength: hullLength,
            units: units,
            inches: inches,
            power: power
        });
    }

    getKVcolor(kv) {
        let category = "";
        if (kv >= 1000 && kv < 2000) {
            category = "low";
        } else if (kv >= 2000 && kv < 3500) {
            category = "medium";
        } else if (kv >= 3500 && kv < 4500) {
            category = "high";
        }
        return category;
    }

    render() {
        return(
            <div id="calculator-page">
                <Navbar selected="speedboats"/>
                <div id="calculator-page-content">
                    <Header />
                    <h1>Calculator</h1>
                    <p>
                        The calculator below can be used to help decide what power system 
                        components to choose for a remote controlled electric speedboat.
                    </p>
                    <div className='text-container'>
                        <img src={HullLengthLogo} alt="logo" className='logo'/>
                        <strong>1.) Hull Length (L):</strong>
                        <div>
                            The first input we need is the length of the hull in inches. 
                            This will be used to determine the power the boat will need 
                            (calculated in the next block).
                        </div>
                        <hr/>
                        <input type='number' onChange={this.onHullLengthChange.bind(this)} value={this.state.hullLength}></input>
                        <select onChange={this.handleUnitsChange.bind(this)}>
                            <option value={'cm'}>cm</option>
                            <option value={'in'}>in</option>
                        </select>
                        <br/>
                        <div>Hull length in inches (L)</div>
                        <div>L = cm x 0.3937</div>
                        <strong>L = {this.state.inches.toFixed(3)}in</strong>
                    </div>
                    <div className='text-container'>
                        <img src={PowerLogo} alt='logo' className='logo'/>
                        <strong>2.) Boat Power (P):</strong>
                        <div>This is the amount of power the boat will need to get decent performance.</div>
                        <hr/>
                        <div>P = 4.279(L)^2 - 163.1(L) + 1901.5</div>
                        <div>P = 4.279({this.state.inches.toFixed(3)})^2 - 163.1({this.state.inches.toFixed(3)}) + 1901.5</div>
                        <div>P = 4.279({(this.state.inches * this.state.inches).toFixed(3)}) - ({(this.state.inches * 163.1).toFixed(3)}) + 1901.5</div>
                        <div>P = {(4.279 * (this.state.inches * this.state.inches)).toFixed(3)} - {(this.state.inches * 163.1).toFixed(3)} + 1901.5</div>
                        <strong>P = {((4.279 * (this.state.inches * this.state.inches)) - (this.state.inches * 163.1) + 1901.5).toFixed()}W</strong>
                    </div>
                    <div className='text-container'>
                        <img src={BattLogo} alt='battery' className='logo' />
                        <strong>3.) Battery:</strong>
                        <div>
                            These are the battery options according to the calculated power needed.
                            The capacity of the battery affects the C rating needed.
                            In general 5000mAh is a decent size.
                        </div>
                        <hr/>
                        <div>Capacity (mAh):</div>
                        <input type='number' value={this.state.battMilliAmps} onChange={this.handleBattMilliAmpChange.bind(this)}></input>
                        <table>
                            <tbody>
                                <tr>
                                    <th>Cell Count</th>
                                    <td>2S</td>
                                    <td>3S</td>
                                    <td>4S</td>
                                    <td>6S</td>
                                    <td>8S</td>
                                </tr>
                                <tr>
                                    <th>Voltage</th>
                                    <td>7.2V</td>
                                    <td>10.8V</td>
                                    <td>14.4V</td>
                                    <td>21.6V</td>
                                    <td>28.8V</td>
                                </tr>
                                <tr>
                                    <th>Current (Amps)</th>
                                    <td>{(this.state.power / 7.2).toFixed(1)}A</td>
                                    <td>{(this.state.power / 10.8).toFixed(1)}A</td>
                                    <td>{(this.state.power / 14.4).toFixed(1)}A</td>
                                    <td>{(this.state.power / 21.6).toFixed(1)}A</td>
                                    <td>{(this.state.power / 28.8).toFixed(1)}A</td>
                                </tr>
                                <tr>
                                    <th>Max Discharge Current (MDC)</th>
                                    <td>{((this.state.power / 7.2) * 1.3 * 1.8).toFixed(1)}A</td>
                                    <td>{((this.state.power / 10.8) * 1.3 * 1.8).toFixed(1)}A</td>
                                    <td>{((this.state.power / 14.4) * 1.3 * 1.8).toFixed(1)}A</td>
                                    <td>{((this.state.power / 21.6) * 1.3 * 1.8).toFixed(1)}A</td>
                                    <td>{((this.state.power / 28.8) * 1.3 * 1.8).toFixed(1)}A</td>
                                </tr>
                                <tr>
                                    <th>C Rating</th>
                                    <td>{(((this.state.power / 7.2) * 1.3 * 1.8) / (this.state.battMilliAmps / 1000)).toFixed()}C</td>
                                    <td>{(((this.state.power / 10.8) * 1.3 * 1.8) / (this.state.battMilliAmps / 1000)).toFixed()}C</td>
                                    <td>{(((this.state.power / 14.4) * 1.3 * 1.8) / (this.state.battMilliAmps / 1000)).toFixed()}C</td>
                                    <td>{(((this.state.power / 21.6) * 1.3 * 1.8) / (this.state.battMilliAmps / 1000)).toFixed()}C</td>
                                    <td>{(((this.state.power / 28.8) * 1.3 * 1.8) / (this.state.battMilliAmps / 1000)).toFixed()}C</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='text-container'>
                        <img src={MotorLogo} alt='battery' className='logo' />
                        <strong>4.) Motor:</strong>
                        <div>
                            Ah, finally... the motor section!
                        </div>
                        <div>
                            The main things to look at when choosing a motor is 
                            the KV rating and size.
                            In general, larger boats run better on low KV 
                            motors (bigger with more torque), smaller boats run 
                            better on high KV motors (lighter with high RPM).
                        </div>
                        <hr/>
                        <div>Max Unloaded RMP:</div>
                        <div>
                            The max RPM is chosen according to the type of hull 
                            the speedboat has. 
                            With submerged props, like on MONO hulls, you should aim 
                            for a lower max RPM. The prop being completely under water
                            causes more draw on the motor, so too much RPM along with a 
                            prop that's too big will cause too much heat.
                            Surface piercing props, like on OUT-RIGGER hulls, can 
                            get away with higher RPM's as they don't cause as much draw 
                            on the motor.
                        </div>
                        <div>Guide:</div>
                        <div>22 500 &lt;-----------------------&gt; 33 000</div>
                        <div>MONO, CAT, TUNNEL, HYDRO, RIGGER</div>
                        <input type='number' value={this.state.rpm} onChange={this.handleRpmChange.bind(this)}></input>
                        <div>Motor KV = RPM / BattV</div>
                        <table>
                            <tbody>
                                <tr>
                                    <th>Cell Count</th>
                                    <td>2S</td>
                                    <td>3S</td>
                                    <td>4S</td>
                                    <td>6S</td>
                                    <td>8S</td>
                                </tr>
                                <tr>
                                    <th>Voltage</th>
                                    <td>7.2V</td>
                                    <td>10.8V</td>
                                    <td>14.4V</td>
                                    <td>21.6V</td>
                                    <td>28.8V</td>
                                </tr>
                                <tr>
                                    <th>Motor KV</th>
                                    <td className={"kv " + this.getKVcolor(this.state.rpm / 7.2)}>{(this.state.rpm / 7.2).toFixed()}KV</td>
                                    <td className={"kv " + this.getKVcolor(this.state.rpm / 10.8)}>{(this.state.rpm / 10.8).toFixed()}KV</td>
                                    <td className={"kv " + this.getKVcolor(this.state.rpm / 14.4)}>{(this.state.rpm / 14.4).toFixed()}KV</td>
                                    <td className={"kv " + this.getKVcolor(this.state.rpm / 21.6)}>{(this.state.rpm / 21.6).toFixed()}KV</td>
                                    <td className={"kv " + this.getKVcolor(this.state.rpm / 28.8)}>{(this.state.rpm / 28.8).toFixed()}KV</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/>
                        <div>Motor Power = Boat Power x 1.4</div>
                        <div>Motor Power = {this.state.power.toFixed()} x 1.4</div>
                        <strong>Motor Power = {(this.state.power * 1.4).toFixed()}W</strong>
                        <br/>
                        <br/>
                        <div>Motor Size (grams) = Motor Power / 4.5</div>
                        <div>Motor Size (grams) = {(this.state.power * 1.4).toFixed()} / 4.5</div>
                        <strong>Motor Size (grams) = {((this.state.power * 1.4) / 4.5).toFixed()}g</strong>
                    </div>
                    <div className='text-container'>
                        <img src={PropLogo} alt="logo" className='logo'/>
                        <strong>5.) Propeller:</strong>
                        <div>This is the size prop that the boat will need to get decent performance.</div>
                        <hr/>
                        <div>Diameter = 11.452 x (Boat power)^(0.1787)</div>
                        <div>Diameter = 11.452 x ({this.state.power.toFixed(3)})^(0.1787)</div>
                        <div>Diameter = 11.452 x ({(Math.pow(this.state.power, 0.1787)).toFixed(3)})</div>
                        <strong>Diameter = {(11.452 * Math.pow(this.state.power, 0.1787)).toFixed(1)}mm</strong>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Calculator;