import React, { PureComponent } from 'react';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import './styles.scss';

class DropdownComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            hidden: this.props.hidden
        };
    }

    toggle() {
        this.setState({
            hidden: !this.state.hidden
        });
    }

    render() {
        let arrow = <ExpandLess className='arrow' />;
        if (this.state.hidden) {
            arrow = <ExpandMore className='arrow' />
        }

        return(
            <div className="dropdown">
                { arrow }
                <h2 id='title' onClick={this.toggle.bind(this)}>{this.props.title}</h2>
                <div className={'container' + (this.state.hidden ? ' hide' : '')}>
                    { this.props.content }
                </div>
            </div>
        );
    }
}

export default DropdownComponent;